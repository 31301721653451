<!-- 生态合作 -->
<template>
  <div class="wrapper pageBox">
    <!-- 顶部banner -->
    <div class="banner">
      <img src="https://zhongjia-data.oss-cn-hangzhou.aliyuncs.com/web/assets/img/banner1.png">
      <div class="bannerTitle">
        <img :src="bannerTitle" alt="">
        <div class="banner-info">中嘉数科与您一起，共筑未来数字生活</div>
        <div class="join-btn" @click="clickBtn">
          立即加入
        </div>
      </div>
    </div>
    <!-- 内容区域 -->
    <div class="connect">
      <div class="conBox">
        <!-- 为什么加入中嘉 -->
        <div class="why-join">
          <div class="join-title">为何加入中嘉数科生态伙伴？</div>
          <div class="join-content">
            <div class="join-item">
              <img :src="icon1" alt="">
              <div class="item-text">资源生态提供海量</div>
              <div class="item-text">供需合作机会</div>
            </div>
            <div class="join-item">
              <img :src="icon2" alt="">
              <div class="item-text">数字化集成类项目</div>
              <div class="item-text">强强联合</div>
            </div>
            <div class="join-item">
              <img :src="icon3" alt="">
              <div class="item-text">开源平台技术赋能</div>
              <div class="item-text">研发端</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 招募对象区域 -->
      <div class="topText">
        <div class="title">招募对象</div>
      </div>
      <div class="card-box">
        <div class="card">
          <el-row :gutter="35">
            <el-col :span="12">
              <div class="card-item">
                研发生产ISV生态伙伴
              </div>
            </el-col>
            <el-col :span="12">
              <div class="card-item">
                智能终端生产生态伙伴
              </div>
            </el-col>
            <el-col :span="12">
              <div class="card-item">
                系统集成商生态伙伴
              </div>
            </el-col>
            <el-col :span="12">
              <div class="card-item">
                联合体项目履约生态伙伴
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- 加入流程区域 -->
      <div class="process-box">
        <img :src="processImg" alt="">
        <div class="process-content">
          <div class="process-title">加入流程</div>
          <div class="item-box">
            <div class="process-item">
              <img :src="img1" alt="">
              <div class="process-order">
                <img :src="order1" alt="">
              </div>
              <div class="item-title">填写基本信息</div>
            </div>
            <div class="process-line" />
            <div class="process-item">
              <img :src="img2" alt="">
              <div class="process-order">
                <img :src="order2" alt="">
              </div>
              <div class="item-title">中嘉友好洽谈</div>
            </div>
            <div class="process-line" />
            <div class="process-item">
              <img :src="img3" alt="">
              <div class="process-order">
                <img :src="order3" alt="">
              </div>
              <div class="item-title">签署生态合作协议</div>
            </div>
            <div class="process-line" />
            <div class="process-item">
              <img :src="img4" alt="">
              <div class="process-order">
                <img :src="order4" alt="">
              </div>
              <div class="item-title">共同赋能未来生活</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 立即加入 -->
      <div class="now-box">
        <img :src="spotsImg" alt="">
        <div class="now-content">
          <div class="now-title">立即成为精英生态伙伴，共筑未来数字生活</div>
          <div class="now-btn" @click="clickBtn">立即加入</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Cooperation',
  data() {
    return {
      bannerTitle: require('@/assets/img/banner-title.png'),
      icon1: require('@/assets/img/icon1.png'),
      icon2: require('@/assets/img/icon2.png'),
      icon3: require('@/assets/img/icon3.png'),
      processImg: 'https://zhongjia-data.oss-cn-hangzhou.aliyuncs.com/web/assets/img/process.png',
      order1: require('@/assets/img/1.png'),
      order2: require('@/assets/img/2.png'),
      order3: require('@/assets/img/3.png'),
      order4: require('@/assets/img/4.png'),
      img1: require('@/assets/img/01.png'),
      img2: require('@/assets/img/02.png'),
      img3: require('@/assets/img/03.png'),
      img4: require('@/assets/img/04.png'),
      spotsImg: require('@/assets/img/spots.png')
    };
  },
  created() {},
  mounted() {},
  methods: {
    clickBtn() {
      this.$router.push({
        path: '/cooperation/joinUs'
      });
    }
  }
};
</script>

<style lang='scss' scoped>
.topText {
  height: 215px;
}
.connect {
  .conBox {
    margin: 0 auto;
    width: 1280px;
    margin-top: -72px;
    position: relative;
    z-index: 9999;
    .why-join {
      width: 100%;
      height: 391px;
      background: #ffffff;
      border-radius: 8px;
      box-shadow: 0px 0px 15px 7px rgba(147, 161, 190, 0.1);
      overflow: hidden;
      // z-index: 99999;
      .join-title {
        text-align: center;
        margin-top: 68px;
        font-size: 34px;
        text-align: center;
        color: #333333;
        margin-bottom: 65px;
      }
      .join-content {
        display: flex;
        justify-content: space-around;
        .join-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 40px;
            height: 40px;
            margin-bottom: 32px;
          }
          .item-text {
            font-size: 18px;
            font-weight: 400;
            text-align: center;
            color: #252b3a;
            line-height: 36px;
          }
        }
      }
    }
  }
  .card-box {
    width: 100%;
    height: 438px;
    background: rgba(242,244,246,0.70);
    margin-bottom: 100px;
    .card {
      margin: 0 auto;
      width: 1280px;
      padding-top: 60px;
      .card-item {
        cursor: default;
        width: 100%;
        height: 150px;
        line-height: 150px;
        background: #ffffff;
        border-radius: 4px;
        margin-bottom: 20px;
        font-size: 20px;
        text-align: center;
        color: #252b3a;
        &:hover {
          box-shadow: 0px 0px 10px 3px rgba(100,112,139,0.10);
        }
      }
    }
  }
  .process-box {
    width: 100%;
    height: 430px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .process-content {
      width: 1280px;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      margin-top: 73px;
      .process-title {
        font-size: 36px;
        text-align: center;
        color: #ffffff;
        margin-bottom: 80px;
      }
      .item-box {
        display: flex;
        justify-content: space-around;
        .process-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          img {
            width: 104px;
            height: 104px;
            margin-bottom: 25px;
          }
          .process-order {
            position: absolute;
            left: 0;
            top: 0;
            img {
              height: 78px;
              width: auto;
              transform: translateX(-50%);
            }
          }
          .item-title {
            font-size: 16px;
            font-weight: 400;
            text-align: center;
            color: #ffffff;
          }
        }
        .process-line {
          height: 1px;
          width: 120px;
          border-top: 1px dashed #fff;
          margin-top: 52px;
        }
      }
    }
  }
  .now-box {
    width: 100%;
    height: 231px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .now-content {
      width: 1280px;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      margin-top: 55px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .now-title {
        font-size: 32px;
        font-weight: 400;
        text-align: center;
        color: #252b3a;
        margin-bottom: 35px;
      }
      .now-btn {
        cursor: pointer;
        width: 191px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        background: linear-gradient(0deg,#47bbff 0%, #3898ff 100%);
        border-radius: 3px;
        font-size: 18px;
        color: #ffffff;
      }
    }
  }
}

</style>
